import {bindable, inject} from 'aurelia-framework';

const moment = require("moment");
import {Moment} from "moment";
import {TaskListItemDuration} from "../../../views/patient/pflegeplanung/taskListItemDuration";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {GMTCatalog} from "../../../views/patient/pflegeplanung/GMTCatalog";
import {QuestionnaireService} from 'resources/services/QuestionnaireService';
import {PKMS} from "../../classes/IPKMSItem";
import {FhirService} from "../../services/FhirService";
import {fhirEnums} from "../../classes/fhir-enums";
import {NitTools} from "../../classes/NursitTools";
import {I18N} from "aurelia-i18n";
import IMappingAssessmentDiagnose = PKMS.IMappingAssessmentDiagnose;
import ResourceType = fhirEnums.ResourceType;
import CarePlanStatus = fhirEnums.CarePlanStatus;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import HTTPVerb = fhirEnums.HTTPVerb;
import EventStatus = fhirEnums.EventStatus;
import {ConfigService} from "../../services/ConfigService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import RequestIntent = fhirEnums.RequestIntent;
import {CarePlanService} from "../../services/CarePlanService";

@inject(FhirService, I18N, CarePlanService)
export class PatientTodoListShort {
    @bindable patient;

    i18n: I18N;
    fhirService: FhirService;

    carePlan: any;
    carePlanProcedureRequests = [];
    procedureRequests = [];
    currentDay: Moment;
    currentDayValue;
    codeSystem;
    isLoading = true;
    changedDateTimeout = null;
    carePlanService = null;

    constructor(fhirService: FhirService, i18n: I18N, carePlanService: CarePlanService) {
        this.fhirService = fhirService;
        this.i18n = i18n;
        this.carePlanService = carePlanService;
    }

    async patientChanged(newPatient) {
        this.codeSystem = await this.carePlanService.getGMTCodeSystem(this.patient);
        this.carePlanProcedureRequests = [];
        this.procedureRequests = [];

        if (!newPatient) {
            this.isLoading = false;
            this.carePlan = undefined;
            return;
        }

        this.isLoading = true;
        this.carePlan = await this.loadCarePlan();

        if (this.carePlan) {
            this.carePlanProcedureRequests = await this.loadCarePlanProcedureRequests();
            await this.loadProcedureRequests();
        } else {
            this.isLoading = false;
        }
    }

    attached() {
        this.currentDay = moment();
        this.currentDayValue = this.getDayName();
    }

    loadCarePlan() {
        if (this.patient && this.patient.encounterId) {
            let context = `${ResourceType.encounter}/${this.patient.encounterId}`;
            
            return this.fhirService.fetch(`${ResourceType.carePlan}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${context}&status=${CarePlanStatus.active}`).then((result) => {
                return result[0];
            });
        } else return undefined;
    }

    async loadCarePlanProcedureRequests() {
        if (this.carePlan && this.carePlan.id) {
            let basedOn = `CarePlan/${this.carePlan.id}`;

            return this.fhirService.fetch(`${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}?based-on=${basedOn}&status=active`);
        } else return [];
    }

    async loadProcedureRequests() {
        if (!this.patient) {
            this.procedureRequests = [];
            return;
        }

        this.isLoading = true;

        const dateFrom = moment(this.currentDay).startOf('day');
        const dateTo = moment(this.currentDay).endOf('day');
        const resources = await this.fhirService.fetch(`${ResourceType.procedure}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}&date=ge${dateFrom.toJSON()}&date=le${dateTo.toJSON()}&_include=Procedure:based-on:${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}`);
        const procedures = resources.filter((resource) => resource.resourceType === ResourceType.procedure);
        const procedureRequests = resources.filter((resource) => resource.resourceType === (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest));

        this.carePlanProcedureRequests.forEach((procedureRequest) => {
            const existingProcedureRequest = procedureRequests.find((request) => request.id === procedureRequest.id);

            if (!existingProcedureRequest) {
                procedureRequests.push(procedureRequest);
            }
        });

        const groupedProcedureRequests = [];

        procedureRequests
            .map((procedureRequest) => CarePlanService.tempConvertProcedureRequest(this.patient, procedureRequest, this.codeSystem))
            .filter((procedureRequest) => procedureRequest && procedureRequest.intent === RequestIntent.plan)
            .forEach((pr) => {
                const procedureRequest = CarePlanService.tempConvertProcedureRequest(this.patient, pr, this.codeSystem);
                const rootData = CarePlanService.findCode(procedureRequest.code?.coding?.[0]?.code?.split('.')[0], this.codeSystem);

                if (!rootData) {
                    console.warn(`[patient-todo-list-short] Unrecognized code ${procedureRequest.code?.coding?.[0]?.code} for ServiceRequest/ProcedureRequest ${procedureRequest.id} in GMT Code System. Skipping.`)
                    return
                }
                
                const parsedCodeSystem = CarePlanService.parseCodeSystem(rootData);
                const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);

                if (!procedureRequest.occurrenceTiming.event) {
                    procedureRequest.occurrenceTiming.event = [];
                }

                if (parsedDuration.type !== 'interval') {
                    return;
                }

                let group = groupedProcedureRequests.find((gpr) => gpr.codeSystem.code === parsedCodeSystem.code);

                if (!group) {
                    group = {
                        procedureRequests: [],
                        codeSystem: parsedCodeSystem,
                        procedures: [],
                        isInDateTime: false,
                        totals: {
                            total: 0,
                            completed: 0,
                            percentage: 0,
                            time: 0
                        }
                    }

                    groupedProcedureRequests.push(group);
                }

                group.procedureRequests.push({
                    resource: procedureRequest,
                    duration: parsedDuration
                });

                procedures.filter((procedure) => {
                    const procedureRequestReference = procedure.basedOn?.find((bo) => bo.reference.startsWith(FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest));

                    if (!procedureRequestReference) return false;

                    const [_, procedureRequestId] = procedureRequestReference?.reference.split('/');

                    return procedureRequest.id === procedureRequestId;
                }).forEach((procedure) => {
                    group.procedures.push(procedure);
                });
            });

        groupedProcedureRequests.forEach((gpr) => {
            gpr.isInDateTime = false;

            gpr.procedureRequests.sort((a, b) => {
                const aStart = moment(a.resource.occurrenceTiming.event[0]);
                const bStart = moment(b.resource.occurrenceTiming.event[0]);

                if (aStart.isBefore(bStart)) return 1;
                else if (bStart.isBefore(aStart)) return -1;

                return 0;
            }).forEach((procedureRequest) => {
                const event = procedureRequest.resource.occurrenceTiming.event;
                const start = moment(event[0]);
                const end = event[1] ? moment(event[1]) : dateTo;

                if (start.isBefore(dateTo) && end.isAfter(dateFrom)) {
                    gpr.isInDateTime = true;
                }
            });

            const procedures = gpr.procedures;
            const completedProcedures = procedures.filter((procedure) => procedure.status == EventStatus.completed && !procedure.notDone);

            gpr.totals.total = gpr.procedureRequests.reduce((acc, procedureRequest) => {
                return acc + CarePlanService.countProcedureIntervals(procedureRequest.resource, moment(this.currentDay).startOf('day'), moment(this.currentDay).endOf('day'), moment(this.currentDay).startOf('day'), procedureRequest.duration);
            }, 0);
            gpr.totals.completed = completedProcedures.length;
            gpr.totals.percentage = Math.max(0, Math.min(100, Math.round((gpr.totals.completed / gpr.totals.total) * 100)));
            gpr.totals.time = completedProcedures.reduce((total, procedure) => {
                return total + moment.duration(moment(procedure.performedPeriod.end).diff(moment(procedure.performedPeriod.start))).asMinutes();
            }, 0);
        })

        this.procedureRequests = groupedProcedureRequests;
        this.isLoading = false;
    }

    getDayName() {
        const todayStart = moment().startOf('day');
        const todayEnd = moment().endOf('day');
        const dateFormatted = this.currentDay.format('DD.MM.YYYY');

        if (this.currentDay.isSameOrAfter(todayStart) && this.currentDay.isBefore(todayEnd)) {
            return `${this.i18n.tr("today")} (${dateFormatted})`;
        } else {
            return dateFormatted;
        }
    }

    async prevDay() {
        if (this.isLoading) return;

        this.currentDay.subtract(1, 'day');
        this.currentDayValue = this.getDayName();

        clearTimeout(this.changedDateTimeout);
        this.changedDateTimeout = setTimeout(() => {
            this.loadProcedureRequests();
        }, 250);
    }

    async nextDay() {
        if (this.isLoading) return;

        this.currentDay.add(1, 'day');
        this.currentDayValue = this.getDayName();

        clearTimeout(this.changedDateTimeout);
        this.changedDateTimeout = setTimeout(() => {
            this.loadProcedureRequests();
        }, 250);
    }
}
