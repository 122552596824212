import {IQuestionnaireDialogSettings, QuestionnaireDialogContainer} from "../questionnaire/questionnaire-dialog-container";
import {NitTools} from "../../classes/NursitTools";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {SubdelegationInfo} from "../../../views/patient/subdelegation";
import {ConfigService} from "../../services/ConfigService";
import {ISubdelegationInfoItem} from "./patient-subdelegation";
import {DialogMessages} from "../../services/DialogMessages";
import {UserService} from "../../services/UserService";

const moment = require('moment');

export class PatientSubdelegationPopup extends QuestionnaireDialogContainer {
    _selectedVersion: string;
    _route = 'subdelegation';
    versions: string[] = [];
    latestVersion : string;
    versionDate : string;
    popupTitle : string;
    isLatest : boolean = true;
    currentInfo : SubdelegationInfo;
    sdInfoItem? : ISubdelegationInfoItem;
    dialogSettings;
    showStornoButton : boolean = false;

    get selectedVersion(): string {
        return this._selectedVersion;
    }

    set selectedVersion(version: string) {
        if (version === this._selectedVersion) return;

        RuntimeInfo.IsLoading = true;
        this.isLatest = parseInt(version) === parseInt(this.latestVersion);
        this.fhirService.get(`QuestionnaireResponse/${this.response.id}/_history/${parseInt(version)}`)
            .then((r : any) => {
                this._selectedVersion = version;
                this.response = r;
                this.currentInfo = new SubdelegationInfo(this.response, this.questionnaire);
                this.versionDate = moment(this.response.meta?.lastUpdated).format(RuntimeInfo.DateTimeFormat);
                this.forcedReadonly = (this.readonly = !this.isLatest) || !UserService.UserHasQualification(['DGKP', 'SSL', 'PPL']);
                this.tooold = this.tooOld = false;
            })
            .catch(e => {
                console.warn(`When getting Version "${version}" of SubDelegation`, e);
            })
            .finally(() => RuntimeInfo.IsLoading = false);
    }

    displayStopDialog() {
        super.displayStopDialog();
    }

    override saveButtonClicked() {
        if (this.currentInfo) {
            this.currentInfo.fromUserId = UserService.UserName;
            this.currentInfo.fromUserDisplay = `${UserService.UserLastName}, ${UserService.UserFirstName}`;
        }

        super.saveButtonClicked();
    }

    override afterResponseStopped(response: any) {
        super.afterResponseStopped(response);

        this.close(false);
    }

    override async activate(settings?: IQuestionnaireDialogSettings): Promise<void> {
        this.dialogSettings = settings;
        await super.activate(settings);
        if (!this.setting) {
            this.setting = ConfigService.GetFormSettings(this.route);
        }

        this.sdInfoItem = settings?.data?.item;
        /*if (this.sdInfoItem?.isExpired) {
            this.saveText = 'Verlängern';
        } else {
            this.saveText = 'Zuweisen';
        } */

        if (this.setting?.settings?.stornoRequiresQualification) {
            this.showStornoButton = UserService.UserHasQualification(this.setting?.settings?.stornoRequiresQualification);
        }

        if (ConfigService.Debug) window["sd"] = this;
    }

    override async afterResponseChanged(response: any) : Promise<void> {
        await super.afterResponseChanged(response);
        if (!response || this.versions.length > 0 || this.selectedVersion) return;

        this.currentInfo = new SubdelegationInfo(response, this.questionnaire);
        const expirationInfo  : string = this.currentInfo.expiration ? "bis " + moment(this.currentInfo.expiration).format(RuntimeInfo.DateFormat) : "unbegrenzt";
        this.popupTitle = `${this.i18n.tr("subdelegationTitle")}: ${this.currentInfo.forUserDisplay} (${this.currentInfo.forUserQualification}, ${expirationInfo})`;

        // only load the versions when not already done
        this.isLoading = true;
        if (!response.meta) response.meta = { versionId: '1' };
        this.versionDate = moment(this.response.meta?.lastUpdated).format(RuntimeInfo.DateTimeFormat);

        try {
            this._selectedVersion = response.meta.versionId;
            this.latestVersion = NitTools.ToString(response.meta.versionId, 2, '0'); // only the latest version *could* be editable

            for (let i = parseInt(response.meta.versionId); i > 0; i--) {
                this.versions.push(NitTools.ToString(i, 2, '0'));
            }
        } catch (ex) {
            console.warn(ex);
        } finally {
            this.isLoading = false;
        }
    }

    async askForExtension() {
        if (this.sdInfoItem?.isExpired) {
            const practitioner = await UserService.GetPractitioner(this.sdInfoItem.forPractitionerId, this.fhirService);
            const newExpiration = UserService.PractitionerHasQualification(practitioner, ['PFA']) ? undefined : moment(new Date()).add(90, 'days').toDate();
            DialogMessages.Dialog(this.dialogService,
                NitTools.Format(this.i18n.tr("confirmExtendSubdelegation"), this.currentInfo.forUserDisplay, moment(newExpiration).format(RuntimeInfo.DateFormat)),
                 this.i18n.tr("confirm"), this.i18n.tr("yes"), this.i18n.tr("no"), true
            )
                .whenClosed(async (e) => {
                    if (e.output) {

                        this.currentInfo.expiration = UserService.PractitionerHasQualification(practitioner, ['PFA']) ? undefined : moment(new Date()).add(90, 'days').toDate();
                        //this.sdInfoItem.isExpired = false;

                        const expirationInfo  : string = this.currentInfo.expiration ? "bis " + moment(this.currentInfo.expiration).format(RuntimeInfo.DateFormat) : "unbegrenzt";
                        this.popupTitle = `${this.i18n.tr("subdelegationTitle")}: ${this.currentInfo.forUserDisplay} (${this.currentInfo.forUserQualification}, ${expirationInfo})`;
                        console.warn(this.sdInfoItem, this.currentInfo);
                    }
                })
                .catch(e => console.warn(e));
        }
    }
}

export interface IVersionInfo {
    versionId: string;
    lastChanged: string;
}
