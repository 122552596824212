import {bindable, inject, TaskQueue} from 'aurelia-framework';

const moment = require("moment");
import {Moment} from "moment";
import {translations} from "../../classes/translations";
import {ModalMedication} from "../modal-medication";
import {DialogService} from "aurelia-dialog";
import {ModalMedicationBulk} from "../modal-medication-bulk";
import {NitTools} from "../../classes/NursitTools";
import {FhirService} from "../../services/FhirService";
import {I18N} from "aurelia-i18n";
import {UserService} from "../../services/UserService";
import {HttpClient} from "aurelia-http-client";
import {DialogMessages} from 'resources/services/DialogMessages';
import {fhirEnums} from "../../classes/fhir-enums";
import {ConfigService} from "../../services/ConfigService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {ModalMedicationAddDiagnostic} from "../modal-medication-add-diagnostic";

@inject(DialogMessages, DialogService, TaskQueue, I18N)
export class PatientMedication {
    @bindable patient;

    dialogService: DialogService;
    _fhirService: FhirService;
    get fhirService(): FhirService {
        if (!this._fhirService)
           this._fhirService = new FhirService();
        
        return this._fhirService;
    }
    
    dialogMessages: DialogMessages;
    taskQueue: TaskQueue;

    currentDay: Moment;
    currentDayValue;

    timeslots = [];
    timeslotsDays = [];

    range = 'day';
    isNextDayActive = false;
    isNightActive = false;
    i18n: I18N;
    medications = [];
    isLoading = false;

    get alphatron(): IAlphatron {
        return PatientMedication.AlphaTron;
    }

    userId: string;

    public static AlphaTron: IAlphatron = undefined;

    constructor(dialogMessages, dialogService, taskQueue, i18n: I18N) {
        this.dialogService = dialogService;
        this.dialogMessages = dialogMessages;
        this.taskQueue = taskQueue;
        this.i18n = i18n;

        if (this.alphatron && this.alphatron.enabled) {
            // get user:pass from env
            let tmp = atob(this.alphatron.userHash);
            let sa = tmp.split(':');
            this.alphatron.user = sa[0];
            this.alphatron.pass = sa[1];

            //check if any button of the patient bin is enabled, to toggle the header "Patient Bin" display
            this.alphatron.enableBin = this.alphatron.buttons.fill || this.alphatron.buttons.open || this.alphatron.buttons.manage;

            // if none of the buttons is active, just disable it again, because it has no use anymore
            this.alphatron.enabled = this.alphatron.buttons.fill || this.alphatron.buttons.open || this.alphatron.buttons.manage || this.alphatron.buttons.unlock;
        }
    }

    alphatronPatientBin(mode: string) {
        let url: string = this.alphatron.device;
        let params = ["mode=%MODE%", "authuser=%EMRUSER%", "authpassword=%EMRPASS%", "user=%LOGINNAME%"];

        if (mode !== 'manage') {
            params.push('patid=%PATIENTID%');
            if (mode === 'open' || mode === 'fill') {
                params.push('showLayout=no', 'allowOpenBins=yes', 'openPatientbins=yes');
            }
        }

        switch (mode) {
            default:
                console.warn('NO COMPATIBLE MODE SET. USER "unlock", "open", "fill", "manage" for mode parameter.');
                break;
            case 'unlock':
                mode = 'UnlockCli';
                break;
            case 'open':
                mode = 'Dispense';
                break;
            case 'fill':
                mode = 'Fill';
                break;
            case 'manage':
                mode = 'BinManagement';
                break;
        }

        url += '?' + params.join('&');

        url = url.replace("%LOGINNAME%", UserService.UserName);
        let patientId = this.patient.id;
        if (this.patient.identifier) {
            let hosp = this.patient.identifier.find(o => o.system?.indexOf("/patientNumber") > -1);
            if (!hosp) hosp = this.patient.identifier.find(o => o.use === "official");
            if (!hosp) hosp = this.patient.identifier.find(o => o.value);

            if (hosp) patientId = hosp.value;
        }

        let encounterId = this.patient.encounter.id;
        if (this.patient.encounter.identifier) {
            let ident: any = this.patient.encounter.identifier.find(o => o.system?.indexOf('/visitNumber') > -1);
            if (!ident) ident = this.patient.encounter.identifier.find(o => o.system.endsWith('/sourceId'));
            if (ident) encounterId = ident.value;
        }

        url = url.replace("%PATIENTID%", encodeURIComponent(patientId));
        url = url.replace("%EMRUSER%", this.alphatron.user);
        url = url.replace("%EMRPASS%", this.alphatron.pass);
        url = url.replace("%MODE%", mode);
        url = url.replace("%ENCOUNTERID%", encodeURIComponent(encounterId));

        let client = new HttpClient();
        RuntimeInfo.IsLoading = true;
        if (ConfigService.Debug) {
            console.debug(`Sending Alphatron ${mode} Request to: "${url}"`);
        }

        RuntimeInfo.IsLoading = true;
        client.get(url)
            .then(result => {
                let s = JSON.stringify(result);
                if (s && s.toUpperCase().indexOf('SUCCESS.') > -1) {
                    if (ConfigService.Debug) console.debug("Case for Patient " + patientId + " should now open");
                } else {
                    let msg = result.response;
                    if (!msg) msg = JSON.stringify(result);
                    throw(msg);
                }
            })
            .catch(e => {
                if (e.response) e = e.response;
                this.dialogMessages.prompt("Something went wrong<br />" + (typeof e === "string" ? e : JSON.stringify(e)), this.i18n.tr("error"), true);
            })
            .finally(() => {
                RuntimeInfo.IsLoading = false;
            })
    }

    attached() {
        document.body.classList.add("no-toolbar-window");
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
    }

    patientChanged() {
        this.currentDay = moment().startOf('day');

        this.setTimeslots();
        this.checkIsNextDayActive();

        this.loadData();
    }

    async loadData() {
        this.isLoading = true;
        this.currentDayValue = this.getDayName();

        this.medications = [{
            name: '',
            requests: []
        }];

        const dateFrom = moment(this.currentDay).startOf('day');
        const dateTo = moment(this.currentDay).endOf('day');

        if (this.isNextDayActive) {
            dateTo.add(1, 'day');
        }

        this.setTimeslots();

        const medicationRequests = await this.fhirService.fetch(`${fhirEnums.ResourceType.medicationRequest}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}`);

        for (let i = 0; i < medicationRequests.length; i++) {
            const medicationRequest: any = medicationRequests[i];

            if (moment(medicationRequest.dispenseRequest.validityPeriod.start).isBefore(dateTo) && moment(medicationRequest.dispenseRequest.validityPeriod.end).isAfter(dateFrom)) {
                const medicationAdministrations = await this.fhirService.fetch(`${fhirEnums.ResourceType.medicationAdministration}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}&prescription=MedicationRequest/${medicationRequest.id}&effective-time=ge${dateFrom.format('YYYY-MM-DDTHH:mmZ')}&effective-time=lt${dateTo.format('YYYY-MM-DDTHH:mmZ')}`);
                const administrationsPerDay = this.getAmountAdministrationsForDay(medicationAdministrations.map((administration) => {
                    return {resource: administration};
                }));

                // if (administrationsPerDay === 0) {
                //     const createdAdministrations = await this.createAdministrationsSchedule(medicationRequest);
                //
                //     this.parseMedicationRequest(medicationRequest, createdAdministrations);
                // } else {
                    this.parseMedicationRequest(medicationRequest, medicationAdministrations);
                // }
            }
        }

        this.isLoading = false;
    }

    createAdministrationsSchedule(medicationRequest) {
        const timesOfDay = [];

        for (let i = 0; i < medicationRequest.dosageInstruction.length; i++) {
            const dosageInstruction = medicationRequest.dosageInstruction[i];
            const repeat = dosageInstruction.timing.repeat;

            if (dosageInstruction.asNeededBoolean) {
                continue;
            }

            if (repeat.timeOfDay) {
                repeat.timeOfDay.forEach((timeOfDay) => {
                    const [h, m, s] = timeOfDay.split(':');

                    timesOfDay.push(moment(this.currentDay).startOf('day').add(h, 'hours').add(m, 'minutes').add(s, 'seconds').toJSON());
                });
            } else {
                switch (repeat.periodUnit) {
                    case 'd': {
                        if (repeat.period == 1) {
                            if (repeat.frequency == 1) {
                                timesOfDay.push(moment(this.currentDay).startOf('day').add(12, 'hours'));
                            } else {
                                const timeChunk = 1440 / repeat.frequency;
                                const start = moment(this.currentDay).startOf('day');

                                for (let i = 0; i < repeat.frequency; i++) {
                                    timesOfDay.push(moment(start.add(timeChunk, 'minutes')));
                                }
                            }
                        }
                        break;
                    }
                    case 'h': {
                        if (repeat.period > 1) {
                            const timesPerDay = 24 / repeat.period;
                            const start = moment(this.currentDay).startOf('day');

                            for (let i = 0; i < timesPerDay; i++) {
                                timesOfDay.push(moment(start.add(repeat.period, 'hours')));
                            }
                        }
                        break;
                    }
                    case 'm': {
                        if (repeat.period > 1) {
                            const timesPerDay = 1440 / repeat.period;
                            const start = moment(this.currentDay).startOf('day');

                            for (let i = 0; i < timesPerDay; i++) {
                                timesOfDay.push(moment(start.add(repeat.period, 'minutes')));
                            }
                        }
                    }
                }
            }
        }

        return Promise.all(this.createAdministrations(medicationRequest, timesOfDay));
    }

    createAdministrations(medicationRequest, timesOfDay = []) {
        const dosageInstruction = medicationRequest.dosageInstruction[0];
        const administrations = [];

        const template = {
            resourceType: fhirEnums.ResourceType.medicationAdministration,
            status: "in-progress",
            medicationReference: {
                reference: medicationRequest.medicationReference.reference,
            },
            subject: {
                reference: `Patient/${this.patient.id}`
            },
            context: {
                reference: `Encounter/${this.patient.encounterId}`
            },
            prescription: {
                reference: `MedicationRequest/${medicationRequest.id}`
            },
            reasonCode: [{
                coding: [{
                    system: RuntimeInfo.SystemHeader + '/medication-status',
                    code: 'open'
                }]
            }],
            dosage: {
                dose: {
                    value: dosageInstruction.doseQuantity.value,
                    unit: dosageInstruction.doseQuantity.unit,
                }
            },
            effectiveDateTime: moment().toJSON()
        };

        timesOfDay.forEach((timeOfDay) => {
            template.effectiveDateTime = timeOfDay;

            administrations.push(this.fhirService.create(NitTools.Clone(template)));
        });

        return administrations;
    }

    parseMedicationRequest(medicationRequest, medicationAdministrations) {
        // medicationAdministrations.forEach((ma) => {
        //   Fhir. Rest. Delete(ma);
        // });

        const administrations = medicationAdministrations.map((administration) => {
            return {
                status: this.getStatus(administration),
                dose: administration.dosage.dose,
                resource: administration
            }
        }).sort((a, b) => {
            const aStart = moment(a.resource.effectiveDateTime);
            const bStart = moment(b.resource.effectiveDateTime);

            if (aStart.isAfter(bStart)) return 1;
            else if (bStart.isAfter(aStart)) return -1;

            return 0;
        });

        this.medications[0].requests.push({
            name: medicationRequest.medicationReference.display,
            administrations: administrations,
            completed: this.getAmountAdministrationsForDay(administrations, true),
            schedule: medicationRequest.dosageInstruction[0].text,
            whenNeeded: medicationRequest.dosageInstruction[0].asNeededBoolean,
            total: this.getAmountAdministrationsForDay(administrations),
            resource: medicationRequest
        });
    }

    getStatus(administration) {
        switch (administration.status) {
            case 'in-progress': {
                return administration.reasonCode[0].coding.find(o => o.system === RuntimeInfo.SystemHeader + "/medication-status").code;
            }
            case 'completed': {
                return 'administered';
            }
            case 'stopped': {
                return 'not-administered';
            }
        }
    }

    checkIsNextDayActive() {
        this.isNightActive = moment().isSameOrAfter(moment(this.currentDay).add(22, 'hours'));

        if (this.range == 'day' || this.range == 'afternoon') {
            this.isNextDayActive = this.isNightActive;
        } else {
            this.isNextDayActive = true;
        }
    }

    getAmountAdministrationsForDay(administrations, onlyCompleted = false) {
        let amount = 0;

        for (let i = 0; i < administrations.length; i++) {
            if (moment(administrations[i].resource.effectiveDateTime).isSameOrAfter(moment(this.currentDay).startOf('day')) && moment(administrations[i].resource.effectiveDateTime).isBefore(moment(this.currentDay).endOf('day')) && (!onlyCompleted || onlyCompleted && administrations[i].resource.status !== 'in-progress')) {
                amount++;
            }
        }

        return amount;
    }

    getDayName() {
        const todayStart = moment().startOf('day');
        const todayEnd = moment().endOf('day');
        const dateFormatted = this.currentDay.format('DD.MM.YYYY');

        if (this.currentDay.isSameOrAfter(todayStart) && this.currentDay.isBefore(todayEnd)) {
            return `${dateFormatted} (${translations.translate("today")})`;
        } else {
            return dateFormatted;
        }
    }

    setTimeslots() {
        this.timeslots = [];
        this.timeslotsDays = [];

        switch (this.range) {
            case 'day': {
                this.timeslots.push(this.createTimeslot(this.currentDay, 240));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 240));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 240));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 240));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 240));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 240));
                break;
            }
            case 'morning': {
                this.timeslots.push(this.createTimeslot(moment(this.currentDay).add(6, 'hours'), 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 120));
                break;
            }
            case 'afternoon': {
                this.timeslots.push(this.createTimeslot(moment(this.currentDay).add(14, 'hours'), 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 120));
                break;
            }
            case 'night': {
                this.timeslots.push(this.createTimeslot(moment(this.currentDay).add(22, 'hours'), 120));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 180));
                this.timeslots.push(this.createTimeslot(this.timeslots[this.timeslots.length - 1].to, 180));

                this.timeslotsDays.push({
                    date: moment(this.currentDay).format('DD.MM.YYYY'),
                    span: 1
                });

                this.timeslotsDays.push({
                    date: moment(this.currentDay).add(1, 'day').format('DD.MM.YYYY'),
                    span: 2
                });
            }
        }
    }

    createTimeslot(dateFrom, range) {
        const from = moment(dateFrom);
        const to = moment(dateFrom).add(range, 'minutes');
        const now = moment();

        return {
            from: from,
            fromText: from.format('HH'),
            to: to,
            toText: to.format('HH'),
            isNow: now.isBetween(from, to)
        }
    }

    editMedication(medicationRequest, medicationAdministration) {
        if (this.patient.isOffline) return;
        let showDate;

        if (this.range === 'night') {
            showDate = this.currentDay;
        }

        this.dialogService.open({
            viewModel: ModalMedication,
            model: {
                request: medicationRequest,
                administration: medicationAdministration,
                patient: this.patient,
                currentDay: this.currentDay,
                showDate
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                Object.assign(medicationAdministration, result.output);

                this.refreshMedications(medicationRequest);
            }
        });
    }

    createMedication(medicationRequest) {
        let showDate;

        if (this.range === 'night') {
            showDate = this.currentDay;
        }

        this.dialogService.open({
            viewModel: ModalMedication,
            model: {
                request: medicationRequest,
                patient: this.patient,
                currentDay: this.currentDay,
                showDate
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                medicationRequest.administrations.push(result.output);

                this.refreshMedications(medicationRequest);
            }
        });
    }

    bulkEditRow(medicationRequest) {
        const administrations = NitTools.Clone(medicationRequest.administrations).map((administration) => {
            return Object.assign({
                request: medicationRequest
            }, administration);
        });

        if (administrations.length === 0) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalMedicationBulk,
            model: {
                administrations,
                patient: this.patient,
                currentDay: this.currentDay
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                medicationRequest.administrations = result.output;

                this.refreshMedications(medicationRequest);
            }
        });
    }

    bulkEditCol(timeslot) {
        const administrations = [];
        const requests = [];
        let showDate;

        if (this.range === 'night') {
            showDate = this.currentDay;
        }

        this.medications[0].requests.forEach((request) => {
            let addRequest = false;

            request.administrations.forEach((administration) => {
                if (this.isInTimeslot(timeslot, administration)) {
                    addRequest = true;
                    administrations.push(Object.assign({
                        request
                    }, administration));
                }
            });

            if (addRequest) {
                requests.push(request);
            }
        });

        if (administrations.length === 0) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalMedicationBulk,
            model: {
                administrations,
                patient: this.patient,
                currentDay: this.currentDay,
                showDate,
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                requests.forEach((request) => {
                    request.administrations.forEach((administration, administrationIdx) => {
                        result.output.forEach((outputAdministration) => {
                            if (administration.resource.id === outputAdministration.resource.id) {
                                request.administrations[administrationIdx] = outputAdministration;
                            }
                        });
                    });

                    this.refreshMedications(request);
                });
            }
        });
    }

    refreshMedications(medicationRequest) {
        const administrations = medicationRequest.administrations;

        medicationRequest.administrations = [];

        this.taskQueue.queueTask(() => {
            medicationRequest.administrations = administrations;
            medicationRequest.administrations.sort((a, b) => {
                const aStart = moment(a.resource.effectiveDateTime);
                const bStart = moment(b.resource.effectiveDateTime);

                if (aStart.isAfter(bStart)) return 1;
                else if (bStart.isAfter(aStart)) return -1;

                return 0;
            });

            medicationRequest.total = this.getAmountAdministrationsForDay(medicationRequest.administrations);
            medicationRequest.completed = this.getAmountAdministrationsForDay(medicationRequest.administrations, true);
        });
    }

    isInTimeslot(timeslot, administration) {
        const dateTime = moment(administration.resource.effectiveDateTime);

        return dateTime.isSameOrAfter(timeslot.from) && dateTime.isBefore(timeslot.to);
    }

    prevDay() {
        if (this.isLoading) return;

        switch (this.range) {
            case 'day': {
                this.currentDay.subtract(1, 'day').startOf('day');
                this.checkIsNextDayActive();
                this.loadData();
                break;
            }
            case 'morning': {
                this.currentDay.subtract(1, 'day').startOf('day');
                this.range = 'night';
                this.checkIsNextDayActive();
                this.loadData();
                break;
            }
            case 'afternoon': {
                this.range = 'morning';
                this.setTimeslots();
                this.checkIsNextDayActive();
                break;
            }
            case 'night': {
                this.range = 'afternoon';
                this.setTimeslots();
                this.checkIsNextDayActive();
                break;
            }
        }
    }

    nextDay() {
        if (this.isLoading || !this.isNextDayActive) return;

        switch (this.range) {
            case 'day': {
                this.currentDay.add(1, 'day').startOf('day');
                this.loadData();
                break;
            }
            case 'morning': {
                this.range = 'afternoon';
                this.setTimeslots();
                break;
            }
            case 'afternoon': {
                this.range = 'night';
                this.setTimeslots();
                break;
            }
            case 'night': {
                if (this.isNightActive) {
                    this.currentDay.add(1, 'day').startOf('day');
                    this.range = 'morning';
                    this.loadData();
                }
                break;
            }
        }

        this.checkIsNextDayActive();
    }

    rangeChanged() {
        if (this.range === 'night' && !this.isNightActive) {
            this.range = 'afternoon';
            return;
        }

        this.setTimeslots();
        this.checkIsNextDayActive();
    }

    addDiagnostic() {
        if (this.patient.isOffline) return;
        this.dialogService.open({
            viewModel: ModalMedicationAddDiagnostic,
            model: {
                patient: this.patient
            }
        }).whenClosed(async (result) => {
            if (!result.wasCancelled) {
                this.isLoading = true;

                const medicationRequest = <any>await this.fhirService.create(result.output);

                const dateFrom = moment(this.currentDay).startOf('day');
                const dateTo = moment(this.currentDay).endOf('day');

                if (this.isNextDayActive) {
                    dateTo.add(1, 'day');
                }

                if (moment(medicationRequest.dispenseRequest.validityPeriod.start).isBefore(dateTo) && moment(medicationRequest.dispenseRequest.validityPeriod.end).isAfter(dateFrom)) {
                    // const createdAdministrations = await this.createAdministrationsSchedule(medicationRequest);
                    this.parseMedicationRequest(medicationRequest, []);
                    // this.parseMedicationRequest(medicationRequest, createdAdministrations);
                }

                this.isLoading = false;
            }
        });
    }
}

export interface IAlphatron {
    enabled?: boolean;
    userHash?: string;
    user?: string;
    pass?: string;
    device?: string;
    buttons?: {
        unlock?: boolean;
        open?: boolean;
        fill?: boolean;
        manage?: boolean
    },
    enableBin?: boolean;
}
