import { DialogController } from 'aurelia-dialog';
import { autoinject } from "aurelia-dependency-injection";

@autoinject
export class ModalEncounterTypeChoice {
    options;
    selectedOption;
    constructor(protected controller : DialogController) {

    }

    async activate(params) {
        this.options = params.options;
    }
}
