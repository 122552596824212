import {bindable} from "aurelia-framework";
import {translations} from "resources/classes/translations";
import {PatientItem} from "../../classes/Patient/PatientItem";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {ConfigService} from "../../services/ConfigService";

export class patientRiskIndicators {
    @bindable patient: PatientItem = undefined;

    patientChanged(newPatient) {
        this.reset();
        if (!newPatient) return;
        this.loadData();
    }

    public riskItems: any[] = [
        {title: this.tr('spi'), field: 'risk_spi', warning: false, cssClass: 'mdi-assignment-turned-in text-success'},
        {title: this.tr('risk_pneumo'), field: 'risk_pneu', warning: false, cssClass: 'mdi-assignment-turned-in text-success'},
        {title: this.tr('risk_decubitus'), field: 'risk_deku', warning: false, cssClass: 'mdi-assignment-turned-in text-success'},
        {title: this.tr('risk_fall'), field: 'risk_sturz', warning: false, cssClass: 'mdi-assignment-turned-in text-success'},
        {title: this.tr('risk_fall_may'), field: 'risk_sturz_mai', warning: false, cssClass: 'mdi-assignment-turned-in text-success'},
        {title: this.tr('risk_vdd_short2'), field: 'risk_vdd', warning: false, cssClass: 'mdi-assignment-turned-in text-success'},
        {title: this.tr('risk_nrs'), field: 'risk_nrs', warning: false, cssClass: 'mdi-assignment-turned-in text-success'}
    ];

    tr(id) {
        return translations.translate(id);
    }

    unknownClass = "mdi mdi-help-outline text-muted";
    warningClass = "mdi mdi-error-outline text-danger";
    noWarningClass = "mdi mdi-assignment-turned-in text-success";

    reset() {
        this.riskItems.forEach(item => {
            item.cssClass = ConfigService.Debug ? this.unknownClass : this.noWarningClass;
            item.warning = undefined;
        })
    }

    loadData() {
        if (!this.patient) return;

        for (let a = 1; a < this.patient.questionnaireResponses.filter(o => ["amended", "completed"].indexOf(o.status) > -1).length; a++) {
            let assessment = this.patient.questionnaireResponses[a];

            for (let i = 0; i < this.riskItems.length; i++) {
                if (typeof this.riskItems[i].warning === "undefined") {
                    let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessment, this.riskItems[i].field);

                    if (item) {
                        let val = Fhir.QuestionnaireResponse.GetKeyValueAsNumber(item);

                        if (typeof val !== "undefined") {
                            let warn = val > 0;

                            this.riskItems[i].warning = warn;
                            this.riskItems[i].cssClass = warn ? this.warningClass : this.noWarningClass;
                        }
                    }
                }
            }
        }

        if (this.patient.pkms_relevant) {
            this.riskItems[0].warning = true;
            this.riskItems[0].cssClass = this.warningClass;
        }

        if (ConfigService.Debug) {
            let notFound = this.riskItems.filter(o => typeof o.warning === "undefined");
            if (notFound.length > 0) {
                console.debug("Nicht gefundene Felder:", notFound);
            }
        }
    }
}
