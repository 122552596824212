import {bindable} from "aurelia-templating";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {UserService} from "../../services/UserService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {LocationService} from "../../services/LocationService";
import {NitTools} from "../../classes/NursitTools";
import {PractitionerEdit} from "../../../views/practitioner-edit";
import {FhirService} from "../../services/FhirService";
import {HumanName} from "../../classes/HumanName";
const moment = require('moment');

@autoinject
export class ModalPatientSubdelegationAdd {
    @bindable practitioners : any[];
    @bindable patient : PatientItem;
    @bindable selectedPractitioner : any;
    @bindable filter : string;
    displayPractitioners : any[];
    wards : any[] = [];
    wardName : string;
    durationText: string = 'unbegrenzt';
    isLoading : boolean = false;

    filterChanged(newValue : string) {
        if (!newValue) {
            this.displayPractitioners = NitTools.Clone(this.practitioners);
        } else {
            this.displayPractitioners = [];

            for (const prac of this.practitioners) {
                if  (prac.name?.[0]?.family) {
                    if (prac.name[0].family.toUpperCase().startsWith(String(newValue).toUpperCase())) {
                        this.displayPractitioners.push(prac);
                        continue;
                    }
                }

                if (String(this.getUserAccount(prac)).toUpperCase().startsWith(String(newValue).toUpperCase())) {
                    this.displayPractitioners.push(prac)
                }
            }
        }
    }

    getUserAccount(prac) : string {
        return prac.identifier?.find(o=>o.system.endsWith('smile-account-id'))?.value;
    }

    async loadPractitioners() {
        try {
            let url = `Practitioner?active=true`;

            const fs = new FhirService();
            const rolesAndPractitioners = await fs.fetch(url, true, undefined, true);
            const pracs = rolesAndPractitioners.filter(o => o.resourceType === "Practitioner");
            let tmpPracs = [];

            for (const prac of pracs.filter(o => o.name && o.name[0]
                && o.active === true
                && NitTools.IsArray(o.qualification)
                && o.qualification.find(q => q.code?.coding.find(c => c.code === 'PFA' || c.code === 'PA'))
                && o.id !== UserService.Practitioner?.id
            )) {
                let div = HumanName.GetText(prac.name[0]);
                const userId = this.getUserAccount(prac);
                if (userId) {
                    div = `${userId} - ${div}`;
                }

                prac.text = {
                    div: div
                }

                const quali = prac.qualification.map(p => p.code).map(c => c.coding[0]?.code).filter(o=>o).join(', ')
                if (quali) {
                    prac.text.div += ` [${quali}]`;
                }

                tmpPracs.push(prac);
            }

            tmpPracs.sort((a,b) => {
                return a.text.div.localeCompare(b.text.div);
            });

            this.practitioners = tmpPracs
            this.filter = '';
        }
        catch (e) {
            console.warn(e);
        }
        finally {
            this.isLoading = false;
        }
    }

    resultPractitioner;
    selectedPractitionerChanged(newPrac) {
        if (typeof newPrac === "string")
            newPrac = this.practitioners.find(o=>o.id === newPrac);

        if (UserService.PractitionerHasQualification(newPrac, ['PFA'])) {
            this.durationText = 'mit unbefristeter Dauer';
        } else {
            this.durationText = 'mit Dauer bis ' + moment(new Date()).add(90, 'days').format(RuntimeInfo.DateFormat);
        }

        this.resultPractitioner = newPrac;
    }

    constructor(protected controller: DialogController, protected locationService : LocationService) {
        this.wards = NitTools.Clone(locationService.wards);
    }

    activate(params) {
        Object.assign(this, params);

        this.isLoading = true;
        window.setTimeout(o => this.loadPractitioners(), 250);
    }
}
