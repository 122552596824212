import {bindable, inject, TaskQueue} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';

const moment = require("moment");
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {I18N} from "aurelia-i18n";

@inject(DialogService, FhirService, I18N, TaskQueue)
export class PatientCurveMedication {
    @bindable patient;
    @bindable dateFrom: Date;
    @bindable dateTo: Date;
    @bindable vitalAxes: number = 0;

    dialogService: DialogService;
    fhirService: FhirService;
    taskQueue: TaskQueue;
    i18n: I18N;
    isLoading = false;

    shown: boolean = true;

    medications = [];
    dayslots = [];
    timeslots = [];

    details = {
        date: '',
        status: '',
        dosage: '',
        top: '0px',
        left: '0px',
        opacity: 0
    };

    medTable;
    columnWidth;

    resize;

    constructor(dialogService, fhirService: FhirService, i18n: I18N, taskQueue: TaskQueue) {
        const that = this;

        this.dialogService = dialogService;
        this.fhirService = fhirService;
        this.i18n = i18n;
        this.taskQueue = taskQueue;

        this.resize = function () {
            that.getColumnWidth();
        }
    }

    attached() {
        this.getColumnWidth();
        this.medications = [];

        window.addEventListener('resize', this.resize);
    }

    detached() {
        window.removeEventListener('resize', this.resize);
    }

    getColumnWidth() {
        const medTableBoundingClientRect = this.medTable.getBoundingClientRect();

        this.columnWidth = Math.round((medTableBoundingClientRect.width - (this.vitalAxes * 70)) / this.dayslots.length);
    }

    toggleExpand(node) {
        this.shown = !this.shown;

        if (!this.shown) {
            node.classList.add('collapsed');
        } else {
            node.classList.remove('collapsed');
        }
    }

    patientChanged(patient) {
        this.loadData();
    }

    dateFromChanged(date) {
        this.loadData();
    }

    dateToChanged(range) {
        this.loadData();
    }

    loadData() {
        if (!this.dateFrom || !this.dateTo || !this.patient || this.isLoading) {
            return;
        }

        this.medications = [];
        this.isLoading = true;

        // wait so both datefrom and dateto get synced
        setTimeout(() => {
            const momentDateFrom = moment(this.dateFrom).startOf('day');
            const momentDateTo = moment(this.dateTo).add(1, 'day').startOf('day');

            this.setTimelots();

            this.fhirService.fetch(`${fhirEnums.ResourceType.medicationRequest}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}`, true).then((medicationRequests: any[]) => {
                return Promise.all(medicationRequests.filter((medicationRequest: any) => {
                    if (medicationRequest && medicationRequest.dispenseRequest && medicationRequest.dispenseRequest.start && medicationRequest.dispenseRequest.end) {
                        return moment(medicationRequest.dispenseRequest.validityPeriod.start).isBefore(momentDateTo)
                            && moment(medicationRequest.dispenseRequest.validityPeriod.end).isAfter(momentDateFrom);
                    } else
                        return false;
                }).map((medicationRequest: any) => {
                    return this.fhirService.fetch(`${fhirEnums.ResourceType.medicationAdministration}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}&prescription=MedicationRequest/${medicationRequest.id}&effective-time=ge${momentDateFrom.format('YYYY-MM-DDTHH:mmZ')}&effective-time=lt${momentDateTo.format('YYYY-MM-DDTHH:mmZ')}`).then((medicationAdministrations) => {
                        this.parseMedicationRequest(medicationRequest, medicationAdministrations);
                    });
                }));
            }).then(() => {
                this.isLoading = false;
                this.taskQueue.queueTask(this.getColumnWidth.bind(this));
            });
        }, 30);
    }

    parseMedicationRequest(medicationRequest, medicationAdministrations) {
        const administrations = medicationAdministrations.map((administration) => {
            return {
                status: this.getStatus(administration),
                dose: administration.dosage.dose,
                resource: administration
            }
        });

        this.medications.push({
            name: medicationRequest.medicationReference.display,
            administrations
        });
    }

    getStatus(administration) {
        switch (administration.status) {
            case 'in-progress': {
                if (moment().isAfter(moment(administration.effectiveDateTime))) {
                    return 'overdue';
                } else {
                    return administration.reasonCode[0].coding.find(o => o.system === RuntimeInfo.SystemHeader + "/medication-status").code;
                }
            }
            case 'completed': {
                return 'administered';
            }
            case 'stopped': {
                return 'not-administered';
            }
        }
    }

    setTimelots() {
        const that = this;
        const momentDateFrom = moment(this.dateFrom).startOf('day');
        const momentDateTo = moment(this.dateTo).add(1, 'day').startOf('day');
        const diff = momentDateTo.diff(momentDateFrom, 'days');

        this.dayslots = [];
        this.timeslots = [];

        function assignSeparations(days, hourSplits) {
            const hourSpans = 24 / hourSplits;
            const totalSpan = days * hourSpans;

            for (let i = 0; i < totalSpan; i++) {
                const dateFrom = moment(momentDateFrom)

                if (i % hourSpans == 0) {
                    that.dayslots.push({
                        day: dateFrom.format('ddd DD MMM'),
                        span: hourSpans
                    });
                }

                that.timeslots.push({
                    from: dateFrom,
                    to: moment(momentDateFrom.add(hourSplits, 'hours')),
                    date: i % hourSpans == 0 ? '&nbsp;' : dateFrom.format('HH:mm')
                })
            }
        }

        switch (diff) {
            case 1:
                assignSeparations(1, 4);
                break;
            case 2:
                assignSeparations(2, 6);
                break;
            case 3:
                assignSeparations(3, 8);
                break;
            case 4:
                assignSeparations(4, 12);
                break;
            case 5:
                assignSeparations(5, 12);
                break;
            case 6:
                assignSeparations(6, 24);
                break;
            case 7:
                assignSeparations(7, 24);
                break;
        }
    }

    tempTimeOfDay(hour, minutes = 0) {
        return moment().startOf('day').add(hour, 'hours').add(minutes, 'minutes');
    }

    isInTimeslot(timeslot, administration) {
        const time = moment(administration.resource.effectiveDateTime);

        return time.isSameOrAfter(timeslot.from) && time.isBefore(timeslot.to);
    }

    diffInTimeslot(timeslot, administration) {
        const time = moment(administration.resource.effectiveDateTime);
        const period = moment.duration(timeslot.to.diff(timeslot.from)).asMinutes();
        const current = moment.duration(time.diff(timeslot.from)).asMinutes();

        return (current / period * 100).toFixed(2);
    }

    isCurrentInTimeslot(timeslot) {
        const now = moment();

        return now.isSameOrAfter(timeslot.from) && now.isBefore(timeslot.to);
    }

    diffCurrentInTime(timeslot) {
        const now = moment();

        if (timeslot) {
            const period = moment.duration(timeslot.to.diff(timeslot.from)).asMinutes();
            const current = moment.duration(now.diff(timeslot.from)).asMinutes();

            return (current / period * 100).toFixed(2);
        }
    }

    showDetails(event, administration) {
        const boundingRect = event.target.getBoundingClientRect();
        const medTable = this.medTable.getBoundingClientRect();
        let top = (28 + boundingRect.top - medTable.top);
        let left = (35 + boundingRect.left - medTable.left);

        if (left + 125 > medTable.width) {
            left -= 145;
        }

        this.details = {
            date: moment(administration.resource.effectiveDateTime).format('DD.MM.YYYY HH:mm'),
            status: this.i18n.tr(administration.status),
            dosage: `${administration.dose.value} ${administration.dose.unit}`,
            top: top + 'px',
            left: left + 'px',
            opacity: 0.85
        };
    }

    touchTableHideDetails(event) {
        if (!event.target.classList.contains('point')) {
            this.hideDetails();
        }
    }

    hideDetails() {
        this.details.opacity = 0;
    }
}

