import { bindable, inject, TaskQueue } from 'aurelia-framework';
import { HttpClient } from "aurelia-http-client";
import * as Fhir from "../../classes/FhirModules/Fhir";
import { I18N } from "aurelia-i18n";
import { NitTools } from "../../classes/NursitTools";
import { PatientChangeNotifier } from 'resources/services/PatientChangeNotifier';
import { IQuestionnaireList, QuestionnaireService } from 'resources/services/QuestionnaireService';
import { fhirEnums } from "../../classes/fhir-enums";
import { ConfigService } from "../../services/ConfigService";
import { IFormSetting } from "../../classes/IFormSettings";
import { AnalyzeService } from "../../services/analyzeService";
import { PatientItem } from "../../classes/Patient/PatientItem";
import { IRiskFieldsSetting } from "../../services/AnalyzerClass";
import { ICDService } from "../../services/ICDService";
import { UserService } from "../../services/UserService";
import { ISvgPoint, SpiSpiderSvg } from "../../classes/SpiSpiderSvg";
import SystemHeaders from 'resources/classes/SystemHeaders';
import { text } from 'express';
import {FhirService} from "../../services/FhirService";


@inject(PatientChangeNotifier, TaskQueue, I18N)
export class patientSpiSpiderWeb {
    @bindable patient?: PatientItem;
    @bindable noheader: boolean;
    queue: TaskQueue;
    svgParent: HTMLDivElement;
    notifier: PatientChangeNotifier;
    subscriberId: string = undefined;
    ready: boolean = false;
    controlId = 'Spider_Risk_SVG'; // NitTools.UidName();
    ncsA : string;
    ncsP : string;

    async patientChanged(newPatient: PatientItem) {
        await this.updateSvgImage();
    }

    constructor(notifier: PatientChangeNotifier, queue: TaskQueue, protected i18n: I18N) {
        this.notifier = notifier;
        this.queue = queue;

        this.subscriberId = notifier.subscribe(async (patient, resource) => {
            if (resource?.resourceType === 'Flag')
                return;

            await this.updateSvgImage();
        });
    }

    detached() {
        this.notifier.unsubscribe(this.subscriberId);
    }

    private async getSpiderPng(svg: SVGImageElement): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                let [x, y, sW, sH] = svg.getAttribute("viewBox").split(' ');
                const w = parseInt(sW) * 2;
                const h = Math.round(parseInt(sH) * 2.2);

                let canvas: HTMLCanvasElement = document.createElement("canvas");
                let img = new Image(w, h); // document.createElement("img");
                let svgSrc = svg.outerHTML;

                canvas.width = w;
                canvas.height = h;
                canvas.style.width = canvas.width + "px";
                canvas.style.height = canvas.height + "px";

                let ctx = canvas.getContext("2d");

                img.onload = () => {
                    ctx.drawImage(img, 1, 1, ctx.canvas.width, ctx.canvas.height);
                    let src = canvas.toDataURL('image/png');
                    resolve(src);
                };

                let svgBlob: Blob = new Blob([svgSrc], { type: 'image/svg+xml' });

                let reader = new FileReader();
                reader.readAsDataURL(svgBlob);
                reader.onloadend = function () {
                    img.src = <string>reader.result;
                };
            }
            catch (e) {
                console.warn(e);
                reject(e);
            }
        })
    }

    async updateSpiderMedia(svg: SVGImageElement) {
        const patient = this.patient;
        if (this.showSecondarySpider || !svg || !svg.outerHTML || !patient || !patient.latestAssessment || !this.patient.encounterId) return;

        let [x, y, sW, sH] = svg.getAttribute("viewBox").split(' ');
        const w = parseInt(sW) * 2;
        const h = parseInt(sH) * 2;

        const system = `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/fhir/StructureDefinition/spi-spider-svg`;
        const client = Fhir.Rest.GetClient();
        const url = `Media?identifier=${encodeURIComponent(system)}|${this.patient.encounterId}`;
        let media: any;
        const httpResponse = await client.createRequest(url).asGet().send();
        let mediaBundle: any;
        try {
            mediaBundle = <any>JSON.parse(httpResponse.response);
        }
        catch (ex) {
            console.warn(ex);
            mediaBundle = undefined;
        }

        if (mediaBundle && mediaBundle.entry) {
            // remove multiple entries - only one can exist per encounter
            if (mediaBundle.total && mediaBundle.total > 1) {
                if (ConfigService.Debug)
                    console.debug('Removing multiple entries of spi-spider');

                const toDelete = mediaBundle.entry.map(o => o.resource);
                await Fhir.Rest.Bundle(toDelete, fhirEnums.HTTPVerb.delete, fhirEnums.BundleType.transaction);
                mediaBundle = undefined;
                media = undefined;
            }

            if (mediaBundle && mediaBundle.entry[0] && mediaBundle.entry[0].resource) {
                patient.spiSpiderMedia = media = <any>mediaBundle.entry[0].resource;
            }
        }

        let content: any;

        try {
            if (svg && svg.outerHTML) {
                content = {
                    contentType: 'Image/svg+xml',
                    language: 'de-DE',
                    data: btoa(svg.outerHTML.replace(/\n/g, "").replace(/\t/g, "")),
                    title: `${patient.display}, SPI-Spinnen-Diagramm`,
                    creation: new Date().toJSON()
                };
            }
        }
        catch (ex) {
            content = undefined;
            console.warn(ex);

            const testElement = (element) => {
                    try {
                        btoa(element.outerHTML);
                    }
                    catch(err) {
                        console.warn(`Error on serializing "${element.tagName}"`, element);
                        if (element.childElementCount > 0) {
                            for (let i = 0; i < element.childElementCount; i++) {
                                testElement(element.children[i]);
                            }
                        }
                    }
                }

            testElement($(svg.outerHTML)[0]);
        }

        if (!content) {
            console.debug("No Content for Image generated. Will bail out.");
            return;
        }

        media = {
            type: 'photo',
            identifier: [{
                system: system,
                value: patient.encounterId
            },
            {
                system: `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/questionnaire-link/${patient.latestAssessment.id}`,
                value: 'Assessment'
            }],
            subject: { reference: `Patient/${patient.id}` },
            frames: 1,
            id: (patient.spiSpiderMedia && patient.spiSpiderMedia.id) ? patient.spiSpiderMedia.id : NitTools.Uid(),
            resourceType: 'Media',
            content: content,
            width: w,
            height: h
        }

        let subtypeName = 'subtype';
        let encounterName = 'context';
        if (FhirService.FhirVersion > 3) {
            subtypeName = 'modality';
            encounterName = 'encounter';
            media['status'] = 'completed';
        }

        media[encounterName] = {reference: `Encounter/${patient.encounterId}` };
        media[subtypeName] = {
            coding: [{
                system: "http://hl7.org/fhir/media-subtype",
                code: "diagram"
            }]
        };

        if (!patient.spiSpiderMedia || patient.spiSpiderMedia.content.data !== content.data) {
            try {
                const imgSrc = await this.getSpiderPng(svg);
                media.text = {
                    div: `<div xmlns="http://www.w3.org/1999/xhtml">
                        <img src="${imgSrc}" alt="${patient.display}, Pflegeindex"></img>
                    </div>`,
                    status: 'generated'
                };
            }
            catch (e) {
                if (ConfigService.Debug)
                    console.warn(e);
            }

            // don't await, we don't want to block the ui thread
            // noinspection ES6MissingAwait
            Fhir.Rest.Update(media, false);
        }
    }

    @bindable showSecondarySpider: boolean;
    ncsHtml: string = '';

    async updateSvgImage() {
        /*
        this has been moved to the analyzer(s)
        if (!this.patient || !this.patient.currentRisks) {
            this.ncsA = '-';
            this.ncsP = '-';
        } else {
            const findRisk = (risk : string) => {
                const result = this.patient.currentRisks.prediction.find(o=>o.outcome && o.outcome.coding 
                    && o.outcome.coding[0] && o.outcome.coding[0].system 
                    && o.outcome.coding[0].system.toUpperCase().endsWith(risk.toUpperCase()));
                    
                    if (result) {
                        return result.outcome.coding[0];
                    }
                    
                    return undefined;
            }

            const ncsa = findRisk("/risk_ncsa_overview");
            const ncsp = findRisk("/risk_ncsp");
            if (ncsa && ncsa.display) {
                this.ncsA = ncsa.display;
            }

            if (ncsp && ncsp.display) {
                this.ncsP = ncsp.display;
            }
        } */

        if (!this.svgParent) return;
        let svg: SVGImageElement; // = await PatientItem.ensureSpiderSvg(this.patient);

        this.ready = false;
        this.svgParent.innerHTML = '';

        const analyzerVersion = await AnalyzeService.GetAnalyzerVersion(this.patient, this.patient?.latestAssessment)
        const analyzer = AnalyzeService.GetAnalyzer(analyzerVersion);

        if (analyzer) {
            this.ncsHtml = analyzer.getNCSSpiderTextHtml(this.patient);
            if (!this.showSecondarySpider) {
                svg = await analyzer.GetSPISpider(this.patient);
            } else if (this.showSecondarySpider) {
                svg = await analyzer.GetSPISpider2(this.patient);
            }
        }

        if (!svg) {
            const spider = await SpiSpiderSvg.Create(this.patient, this.i18n);
            if (spider && spider.svgImage) {
                svg = spider.svgImage;
            }
        }

        if (!this.svgParent) {
            this.svgParent = document.querySelector(`#${this.controlId}`);
        }

        if (this.svgParent && svg) {
            this.svgParent.innerHTML = '';
            this.svgParent.appendChild(svg);
        }

        this.ready = true;

        window.setTimeout(() => this.updateSpiderMedia(svg), 500);
    }

    async attached() {
        await this.i18n.i18next.loadResources();
        this.queue.queueTask(async () => {
            await this.updateSvgImage();
        });
    }
}
