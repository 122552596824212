import {translations} from "../../classes/translations";
import {bindable} from "aurelia-framework";
import {Router} from "aurelia-router";
import {PatientItem} from "../../classes/Patient/PatientItem";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {PatientService} from "resources/services/PatientService";
import {fhirEnums} from "../../classes/fhir-enums";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../services/ConfigService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {PatientDischarge} from "../../../views/patient/discharge";

export class patientReleaseManagement {
    static inject = [Router, PatientChangeNotifier, PatientService, I18N];
    formId: string = "discharge_form";
    response = undefined;
    hasFormChanges = false;
    router: Router = undefined;
    notifier: PatientChangeNotifier = undefined;
    patientService: PatientService;
    @bindable patient: PatientItem;

    constructor(router: Router, notifier: PatientChangeNotifier, patientService: PatientService, protected i18n: I18N, protected questionnaireService: QuestionnaireService) {
        this.router = router;
        this.notifier = notifier;
        this.patientService = patientService;

        if (ConfigService.Debug) window["releaseMgt"] = this;
        if (ConfigService && ConfigService.cfg && ConfigService.cfg["recare"] && typeof ConfigService.cfg["recare"]["enabled"] === "boolean") {
            this.configRecareEnabled = ConfigService.cfg["recare"]["enabled"];
            PatientDischarge.Recare.enabled = this.configRecareEnabled;
        }
    }

    patientChanged() {
        let config = ConfigService.FormSettings.find(o => o.route === "discharge");
        if (config && config.enabled === false) return;
        if (this.patient) {
            QuestionnaireService.GetQuestionnaireIds()
                .then((result: IQuestionnaireList) => {
                    this.patientService.ensureQuestionnaireExistance(this.patient, result.QDischargeManagementId)
                        .catch((error) => {
                            console.warn(error.message || JSON.stringify(error))
                        })
                })
        }

        this.ensureRecareUrl();
    }

    ensureRecareUrl() {
//    if (ConfigService.Debug) console.debug("[Patient-Release-Management] - ensuring recare is read correct");

        if (!this.patient || this.patient.recareUrl) return;

        if (this.patient.selectedAdditionalInfo) {
            let recareItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.selectedAdditionalInfo, "patient_profile_link", false);
            if (recareItem) {
                let tmpUrl = Fhir.QuestionnaireResponse.GetResponseItemValue(recareItem);
                if (tmpUrl && tmpUrl !== "") {
                    this.patient.recareUrl = tmpUrl;
                }
            }
        }
    }

    configRecareEnabled: boolean = false;

    get hasRecare(): boolean {
        if (!this.patient) return false;
        if (this.configRecareEnabled && !this.patient.recareUrl)
            this.ensureRecareUrl();

        return this.configRecareEnabled && !!this.patient.recareUrl;
    }

    openInRecare() {
        if (!this.patient.recareUrl || this.patient.recareUrl === "") return;
        window.open(this.patient.recareUrl, "_recare", "toolbar=no, statusbar=no");
    }

    openDischargeForm() {
        this.showDischargeForm();
    }

    async showDischargeForm() {
        this.router.navigate("#/encounter/" + this.patient.encounterId + "/discharge")
    }

    get redReasons(): string[] {
        return this.patient && this.patient.releaseInformation && this.patient.releaseInformation.redReasons ? this.patient.releaseInformation.redReasons : [];
    }

    get pctInt() {
        if (!this.patient || typeof this.patient.dischargePercent === "undefined") return NaN;
        return parseInt(String(this.patient.dischargePercent))
    }

    get pctDone() {
        let p = this.pctInt;
        return isNaN(p) ? "0" : p + "%";
    }

    get pctDoneText() {
        if (this.patient) {
            if (typeof this.patient.dischargePercent === "undefined") {
                return translations.translate("no_active_discharge_plan");
            } else {
                return `${this.patient.dischargePercent}%`;
            }
        }

        return "?";
    }

    get color() {
        return this.patient ? this.patient.dischargeColor : "transparent";
    }

    tr(stringId) {
        return translations.translate(stringId);
    }

    attached() {
        if (!this.patient) this.patient = PatientService.LatestPatient;
        if (this.patient && !isNaN(this.patient.dischargePercent) && (!this.patient.releaseInformation.redReasons || this.patient.releaseInformation.redReasons.length === 0)) {
            PatientItem.UpdatePatientDischargePct(PatientItem.SelectedPatient, this.i18n);
        }
    }
}
