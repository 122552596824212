import {bindable, inject, TaskQueue} from "aurelia-framework";
import {PatientItem} from "../../classes/Patient/PatientItem";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {I18N} from "aurelia-i18n";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {HttpClient} from "aurelia-http-client";
import {fhirEnums} from "../../classes/fhir-enums";
import {ConfigService} from "../../services/ConfigService";
import {AnalyzeService} from "../../services/analyzeService";
import {IFormSetting} from "../../classes/IFormSettings";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import { AnalyzerClass } from "resources/services/AnalyzerClass";

@inject(TaskQueue, PatientChangeNotifier, I18N)
export class patientRiskOverview {
    svgImageParent : HTMLDivElement;
    __isUpdating: boolean = false;
    activePageIndex: number = 0;
    i18n: I18N;
    pages: RiskOverViewPage[];
    queue: TaskQueue;
    notifier: PatientChangeNotifier;
    subscriberId: string = undefined;
    @bindable patient: PatientItem = undefined;
    // static __svgImage = "";
    private __svgImage : SVGImageElement = undefined;
    isAttached = false;
    qList: IQuestionnaireList;
    handledByAnalyzer = false;
    ready:boolean = true;
    svgRiskParent : HTMLDivElement;

    set title(value: string) {
        this.setSvgText("overview_title", value);
    }

    get title(): string {
        return this.getText("overview_title");
    }

    get svgImage() : SVGImageElement {
        // return patientRiskOverview.__svgImage;
        return this.__svgImage;
    }

    set svgImage(newValue: SVGImageElement) {
        //patientRiskOverview.__svgImage = newValue;
        this.__svgImage = newValue;
        this.updateArrows();
    }

    constructor(queue: TaskQueue, notifier: PatientChangeNotifier, i18n: I18N) {
        this.queue = queue;
        this.i18n = i18n;
        this.notifier = notifier;
        this.subscriberId = notifier.subscribe(async (patient: PatientItem) => {
            if (this.patient && this.patient.id === patient.id) {
                this.patientChanged(patient).catch(error => console.warn(error));
                await this.updateArrows();
            }
        });


        // TODO: add configuration for risks
        // TODO: move reading of values to RiskAssessment
        this.pages = [
            {
                title: this.i18n.tr("risk_overview"),
                groups: [
                    {
                        tr: false,
                        text: this.i18n.tr("supply_deficit"),
                        isVisible: true,
                        link: 1,
                        valueLinkId: "risk_spi_sum",
                        riskLinkId: "risk_spi", outCome: NaN
                    },
                    {
                        tr: false,
                        text: this.i18n.tr("risk_decubitus"),
                        isVisible: true,
                        link: 2,
                        valueLinkId: "risk_deku_sum",
                        riskLinkId: "risk_deku", outCome: NaN
                    },
                    {
                        tr: false,
                        text: this.i18n.tr("risk_pneumo"),
                        isVisible: true,
                        link: 3,
                        valueLinkId: "risk_pneu_sum",
                        riskLinkId: "risk_pneu", outCome: NaN
                    },
                    {
                        tr: false,
                        text: this.i18n.tr("risk_fall"),
                        isVisible: true,
                        link: 4,
                        valueLinkId: "risk_sturz_sum",
                        riskLinkId: "risk_sturz", outCome: NaN
                    },
                    {
                        tr: false,
                        text: this.i18n.tr("risk_vdd_short"),
                        isVisible: true,
                        link: 5,
                        valueLinkId: "risk_vdd_sum",
                        riskLinkId: "risk_vdd", outCome: NaN
                    },
                    {
                        tr: false,
                        text: this.i18n.tr("risk_malnutrition"),
                        isVisible: true,
                        link: 6,
                        color: "n",
                        valueLinkId: "risk_nrs_sum",
                        riskLinkId: "risk_nrs_calc", outCome: NaN
                    }
                ], outCome: NaN
            },

            {
                title: this.i18n.tr("supply_deficit"),
                outCome: 0,
                groups: [
                    {tr: false, text: this.i18n.tr("spf_movement"), isVisible: true, valueLinkId: "01_01", outCome: 0}, // => risk_activity
                    {tr: false, text: this.i18n.tr("spf_body_care_top"), isVisible: true, valueLinkId: "05_01", outCome: 0},  // => risk_upper_body_care
                    {tr: false, text: this.i18n.tr("spf_body_care_bottom"), isVisible: true, valueLinkId: "05_02", outCome: 0}, // => risk_lower_body_care
                    {tr: false, text: this.i18n.tr("spf_dress_top"), isVisible: true, valueLinkId: "05_03", outCome: 0}, // => risk_upper_body_dress
                    {tr: false, text: this.i18n.tr("spf_dress_bottom"), isVisible: true, valueLinkId: "05_04", outCome: 0}, // => risk_lower_body_dress
                    {tr: false, text: this.i18n.tr("spf_eat"), isVisible: true, valueLinkId: "03_01", outCome: 0}, // => risk_eat
                    {tr: false, text: this.i18n.tr("spf_drink"), isVisible: true, valueLinkId: "03_05", outCome: 0}, // => risk_drink
                    {tr: false, text: this.i18n.tr("spf_urine_excretion"), isVisible: true, valueLinkId: "04_01", outCome: 0}, // => risk_urine
                    {tr: false, text: this.i18n.tr("spf_stool_excretion"), isVisible: true, valueLinkId: "04_03", outCome: 0}, // => risk_stool
                    {tr: false, text: this.i18n.tr("get_knowledge"), isVisible: true, valueLinkId: "06_03", outCome: 0}, // => risk_knowledge
                ]
            },

            {
                title: this.i18n.tr("risk_decubitus"),
                groups: [
                    {tr: false, text: this.i18n.tr("spf_movement"), isVisible: true, valueLinkId: "01_01", outCome: 0}, // => risk_activity
                    {tr: false, text: this.i18n.tr("spf_change_body_position"), isVisible: true, valueLinkId: "01_02", outCome: 0}, // => risk_change_position
                    {tr: false, text: this.i18n.tr("friction_on_transfer"), isVisible: true, valueLinkId: "01_03", outCome: 0}, // => risk_friction
                    {tr: false, text: this.i18n.tr("food_amount_complete"), isVisible: true, valueLinkId: "03_03", outCome: 0}, // => risk_food_amount_total
                    {tr: false, text: this.i18n.tr("skin_moisture"), isVisible: true, valueLinkId: "04_08", outCome: 0}, // => risk_skin_moisture
                    {tr: false, text: this.i18n.tr("sensoric_cognition"), isVisible: true, valueLinkId: "10_01", outCome: 0}, // => risk_sensoric_cognition
                ], outCome: 0
            },

            {
                title: this.i18n.tr("risk_pneumo"),
                groups: [
                    {tr: false, text: this.i18n.tr("spf_movement"), isVisible: true, valueLinkId: "01_01", outCome: 0}, // => risk_activity
                    {tr: false, text: this.i18n.tr("impaired_deglutitions"), isVisible: true, valueLinkId: "03_10", outCome: 0}, // => risk_impaired_deglutitions
                    {tr: false, text: this.i18n.tr("awareness_vigilance"), isVisible: true, valueLinkId: "06_01", outCome: 0}, // => risk_awareness_vigilance
                    {tr: false, text: this.i18n.tr("acute_breathing_impairment"), isVisible: true, valueLinkId: "09_01", outCome: 0}, // => risk_acute_breathing_impairment
                    {tr: false, text: this.i18n.tr("chronic_sick_lungs"), isVisible: true, valueLinkId: "09_02", outCome: 0}, // => risk_chronic_sick_lungs
                    {tr: false, text: this.i18n.tr("respiration_more_than_24h"), isVisible: true, valueLinkId: "09_03", outCome: 0},// => risk_respiration_more_than_24h
                    {tr: false, text: this.i18n.tr("abdom_thok_damage_op"), isVisible: true, valueLinkId: "09_04", outCome: 0},// => TODO
                    {tr: false, text: this.i18n.tr("tracheostoma"), isVisible: true, valueLinkId: "09_06", outCome: 0}// => TODO
                ], outCome: 0
            },

            {
                title: this.i18n.tr("risk_fall"),
                groups: [
                    {tr: false, text: this.i18n.tr("spf_movement"), isVisible: true, valueLinkId: "01_01", outCome: 0}, // risk_activity
                    {tr: false, text: this.i18n.tr("changed_walking"), isVisible: true, valueLinkId: "01_06", outCome: 0}, // => risk_changed_walking
                    {tr: false, text: this.i18n.tr("awareness_vigilance"), isVisible: true, valueLinkId: "06_01", outCome: 0}, // => risk_risk_awareness_vigilance
                    {tr: false, text: this.i18n.tr("disturbances_of_equilibrium"), isVisible: true, valueLinkId: "01_07", outCome: 0}, // => risk_disturbances_of_equilibrium
                    {tr: false, text: this.i18n.tr("fall_pre_history"), isVisible: true, valueLinkId: "01_05", outCome: 0}, // => risk_risk_fall_pre_history
                    {tr: false, text: this.i18n.tr("acute_fall_event"), isVisible: true, valueLinkId: "01_08", outCome: 0}, // => risk_acute_fall_event
                    {tr: false, text: this.i18n.tr("obsessive_elevated_excretion"), isVisible: true, valueLinkId: "04_05", outCome: 0}, // => risk_obsessive_elevated_excretion
                    {tr: false, text: this.i18n.tr("orientation_person_place_time_situation"), isVisible: true, valueLinkId: "06_02", outCome: 0}, // => risk_orientation
                    {tr: false, text: this.i18n.tr("fall_delirium_risk_raising_medication"), isVisible: true, valueLinkId: "06_07", outCome: 0}, // => risk_fall_raising_medication
                    {tr: false, text: this.i18n.tr("seeing"), isVisible: true, outCome: 0, valueLinkId: '07_08'} // => risk_seeing
                ], outCome: 0
            },

            {
                title: this.i18n.tr("risk_vdd"),
                groups: [
                    {tr: false, text: this.i18n.tr("get_knowledge"), isVisible: true, valueLinkId: "06_03", outCome: 0}, // => risk_knowledge
                    {tr: false, text: this.i18n.tr("orientation_person_place_time_situation"), isVisible: true, valueLinkId: "06_02", outCome: 0}, // => risk_orientation
                    {tr: false, text: this.i18n.tr("fall_delirium_risk_raising_medication"), isVisible: true, valueLinkId: "06_07", outCome: 0}, // => risk_fall_raising_medication
                    {tr: false, text: this.i18n.tr("every_day_skills"), isVisible: true, valueLinkId: "06_05", outCome: 0}, // => risk_every_day_skills
                    {tr: false, text: this.i18n.tr("cognition"), isVisible: true, valueLinkId: "06_01", outCome: 0}, // => risk_cognition
                    {tr: false, text: this.i18n.tr("self_initiated_activities"), isVisible: true, valueLinkId: "07_10", outCome: 0}, // => risk_self_initiated_activities
                    {tr: false, text: this.i18n.tr("characteristics_of_challenging_behavior"), isVisible: true, valueLinkId: "07_06", outCome: 0}, // => risk_challenging_behavior
                    {tr: false, text: this.i18n.tr("sleep_awake_rhythm"), isVisible: true, valueLinkId: "08_02", outCome: 0} // => risk_sleep_rhythm
                ], outCome: 0
            },

            {
                title: this.i18n.tr("risk_malnutrition"),
                groups: [
                    {tr: false, text: this.i18n.tr("food_amount_oral"), isVisible: true, valueLinkId: "03_02", outCome: 0}, // => risk_food_amount_oral
                    {tr: false, text: this.i18n.tr("food_amount_total"), isVisible: true, valueLinkId: "03_03", outCome: 0}, // => risk_food_amount_total
                    {tr: false, text: this.i18n.tr("energy_and_nutrient_requirements"), isVisible: true, valueLinkId: "03_12", outCome: 0}, // => risk_energy_requirements
                    {tr: false, text: this.i18n.tr("bmi_influencing_factors"), isVisible: true, valueLinkId: "00_06", outCome: 0}, // => risk_bmi_influencing_factors
                    {tr: false, text: this.i18n.tr("unwanted_weight_loss"), isVisible: true, valueLinkId: "00_04", outCome: 0} // => risk_unwanted_weight_loss
                ], outCome: 0
            }
        ];

        if (ConfigService.Debug) {
            window["riskoverview"] = this;
        }
    }

    analyzer : AnalyzerClass;
    async updatePatientDisplay() {
        const newPatient = this.patient;

        //try {
            if (!this.svgImageParent)
                this.svgImageParent = <HTMLDivElement>document.getElementById("svgRiskOverViewParent");

            if (this.svgImageParent) {
                if (!newPatient || !newPatient.latestAssessment)
                    this.svgImageParent.classList.add('risk-opaque');
                else
                    this.svgImageParent.classList.remove('risk-opaque');
            }

            if (newPatient) {
                const cfg = await ConfigService.LoadConfigOverride(newPatient.ward, newPatient);


                if (newPatient && !newPatient.latestAssessment) {
                    const assessmentSetting : IFormSetting = cfg.forms.find(o=>o.route && o.route === "assessment");
                    if (assessmentSetting) {
                        newPatient.latestAssessment = QuestionnaireService.GetLatestResponseOfName(newPatient, assessmentSetting.questionnaireName, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
                    }
                }

                this.handledByAnalyzer = false;
                const anaVersion = await AnalyzeService.GetAnalyzerVersion(newPatient);
                if (anaVersion) {
                    this.analyzer = AnalyzeService.GetAnalyzer(anaVersion);
                    if (this.analyzer) {
                        if (this.svgRiskParent) {                            
                            const getRiskSunResult = await this.analyzer.GetRiskSun(newPatient);
                            this.handledByAnalyzer = !!getRiskSunResult;
                            if (getRiskSunResult) {
                                this.svgImage = getRiskSunResult;
                                if (this.svgRiskParent) {
                                    this.svgRiskParent.innerHTML = typeof this.svgImage === "string" ? this.svgImage : new XMLSerializer().serializeToString(this.svgImage);
                                }
                            }
                        }
                    }
                }

                await this.loadSvg();
                await this.updateArrows();
            }
        /* }
        catch (e) {
            console.warn(e.message||e);
        }
        finally {
            this.ready = true;
        } */
        this.ready = true;
    }

    async patientChanged(newPatient: PatientItem) {
        this.ready  = false;
        await this.updatePatientDisplay();
    }

    async attached() {
        this.isAttached = true;
        await this.loadSvg();
        await this.updateArrows();
    }

    detached() {
        this.notifier.unsubscribe(this.subscriberId);
    }

    async updateArrows() {
        if (this.handledByAnalyzer) return;

        if (!this.svgImage)
            await this.loadSvg();

        if (!this.svgImage) return;

        if (!this.qList) this.qList = await QuestionnaireService.GetQuestionnaireIds();

        if (this.__isUpdating || !this.patient || !this.svgImage || !this.isAttached) return;
        this.__isUpdating = true;

        window.setTimeout(async () => {
            await this.loadSvg();

            this.reset();
            this.resetArrows();
            this.resetGroupColorsAndValues();
            this.updateArrowsFromRisks(
                QuestionnaireService.GetResponsesOfType(this.patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended])
            );

            this.__isUpdating = false;
        }, 250); // aurelia uses 200ms too
    }

    setOuterColor(index: number, color: string): patientRiskOverview {
        if (typeof color === "undefined" || color === "n") color = "#cccccc";
        let displayId = "border_" + index;
        let $item = $('#' + displayId);
        $item.css("fill", color);

        return this;
    }

    setArrowColor(index: number, color: string): patientRiskOverview {
        if (typeof color === "undefined" || color === "n") color = "";
        $('#arr_' + index + ', #arr_' + index + '_r, #arr_' + index + '_g').hide();
        let displayId = "arr_" + index + (color !== "" ? "_" + color : "");
        $('#' + displayId).show();

        return this;
    }

    getRiskAssessmentValue(id: string): boolean {
        if (!this.patient || !this.patient.currentRisks || !this.patient.currentRisks.prediction) return undefined;
        for (let i = 0; i < this.patient.currentRisks.prediction.length; i++) {
            let pred = this.patient.currentRisks.prediction[i];
            if (pred && pred.outcome && pred.outcome.coding && pred.outcome.coding[0] && typeof pred.outcome.coding[0].code !== "undefined"
                && String(pred.outcome.coding[0].system).toUpperCase().endsWith(id.toUpperCase())) {

                let stringVal = String(pred.outcome.coding[0].code).toUpperCase();
                if (stringVal === "TRUE" || stringVal === "FALSE") {
                    return stringVal === "TRUE";
                }

                let value = parseInt(pred.outcome.coding[0].code);
                if (value < 0) return undefined;

                return value >= 1;
            }
        }

        return undefined;
    }

    updateArrowsOnPage(pageIndex: number): patientRiskOverview {
        if (this.handledByAnalyzer) return;

        for (let i = 0; i <= 9; i++) {
            this.setArrowColor(i, "n");
            this.setOuterColor(i, "n");
        }

        let curPage = this.pages[pageIndex];
        for (let i = 0; i < curPage.groups.length; i++) {
            let group = curPage.groups[i];
            this.setArrowColor(i, group.color);
            group.hasRisk = undefined;

            if (group.riskLinkId) {
                if (this.patient && this.patient.currentRisks) {
                    let s = "Checking Risk for: " + group.riskLinkId;
                    let val = this.getRiskAssessmentValue(group.riskLinkId);

                    if (group.riskLinkId === "risk_nrs_calc") {
                        if (typeof this.getRiskAssessmentValue("risk_nrs_sum") === "undefined")
                            val = undefined;
                    }


                    if (typeof val === "boolean") {
                        s += ' Got Result ' + val;
                        group.hasRisk = val;
                        this.setOuterColor(i, group.hasRisk ? "red" : "green");
                    } else {
                        s += ' NOT FOUND! ';
                    }
                }

                //#region special treatment for NRS / Malnutrition
                /* if (this.patient && this.patient.latestAssessment && group.valueLinkId === "risk_nrs_sum") {
                    // determine whether the calc-result was possible (if not risk_nrs_calc should be -1)
                    let possible = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(this.patient.latestAssessment, 'risk_nrs_calc', -1) > -1;
                    if (possible) {
                        let intVal = Fhir.QuestionnaireResponse.GetResponseItemValueIntByLinkId(this.patient.latestAssessment, group.valueLinkId, 0);
                        group.hasRisk = intVal === 1;
                    }
                } */
                //#endregion
            }
        }

        return this;
    }

    getText(id: string): string {
        let ele = document.getElementById(id);
        return ele ? ele.innerHTML : undefined;
    }

    setSvgText(id: string, text: string) {
        let $ele: any = $('#' + id + ' tspan');
        let $spans = $ele.find("tspan");
        if ($spans.length > 0) {
            $ele = $($spans[0]);
        }

        $ele.text(text);
    }

    getGroup(index: number) {
        let id = "grp_" + index.toString();
        return document.getElementById(id);
    }

    getLabel(index: number) {
        return document.getElementById("label_" + index.toString());
    }

    setLabelText(index: number, text: string) {
        let id = "label_" + index.toString();
        this.setSvgText(id, text);

        if (typeof text === "undefined" || text === "") {
            this.setGroupVisibility(index, false);
        }
    }

    getLabelText(index: number): string {
        let ele = this.getLabel(index);
        if (!ele) {
            console.warn("Label #" + index + " nicht gefunden");
            return undefined;
        } else {
            return ele.innerHTML;
        }
    }

    setGroupVisibility(index: number, isVisible: boolean) {
        let grp = this.getGroup(index);
        if (grp) {
            grp.style.display = isVisible ? "block" : "none";
        } else {
            // console.warn("Gruppe 'grp_" + index + "' nicht gefunden", "SVG:", $("#svg_overview"));
        }

        let lbl = this.getLabel(index);
        if (lbl) {
            lbl.style.display = isVisible ? "block" : "none";
        } else {
            // console.warn("Label 'label_" + index + "' nicht gefunden", "SVG:", $("#svg_overview"));
        }
    }

    setActivePage(index: number): patientRiskOverview {
        if (this.handledByAnalyzer) {
            console.warn("HANDLED!")
            return;
        }

        let page = this.pages[index];

        if (!page) {
            return;
        }

        if (this.activePageIndex === index) return;

        this.activePageIndex = index;
        this.title = page.title;

        for (let i = 0; i <= 9; i++) {
            this.setArrowColor(i, "n");
            this.setGroupVisibility(i, false);
        }

        for (let i = 0; i < page.groups.length; i++) {
            let pageGroup = page.groups[i];

            let label: any = this.getLabel(i);
            if (label) {
                let spans = $(label).find("tspan");
                if (spans.length > 0) {
                    label = <SVGTSpanElement>spans[0];
                }

                $(label).text(pageGroup.text);
            }


            this.setGroupVisibility(i, true);

            let grp = this.getGroup(i);
            if (grp) {
                let $grp = $(grp);
                $grp.off("click");

                if (label) {
                    $(label).off("click");
                }

                if (pageGroup.link && pageGroup.link > 0 && pageGroup.link <= 9) {
                    $grp.on("click", () => {
                        this.setActivePage(pageGroup.link);
                    });

                    if (label) {
                        $(label).on("click", () => {
                            this.setActivePage(pageGroup.link);
                        });
                    }
                }
            } else {
                console.warn('Group ' + i + ' not found');
            }
        }

        this.updateArrowsOnPage(this.activePageIndex);
        return this;
    }

    reset() {
        if (this.handledByAnalyzer) return;
        this.setActivePage(-1);

        // get correct values from page 0
        this.activePageIndex = 0;
        this.resetGroupColorsAndValues();
        this.resetArrows();

        // force refresh
        this.activePageIndex = -1;
        this.setActivePage(0);
    }

    async loadSvg() {
        if (!this.svgImage && !this.handledByAnalyzer && this.patient && this.patient.ward)
        {
            await ConfigService.LoadConfigOverride(this.patient.ward, this.patient);
            let svgName = 'risk_overview_2_2.svg';
            const anaVersion = await AnalyzeService.GetAnalyzerVersion(this.patient);

            if (anaVersion) {
                const analyzer = AnalyzeService.GetAnalyzer(anaVersion);
                if (analyzer && analyzer.riskFieldsSetting && analyzer.riskFieldsSetting.svgRisks) {
                    svgName = analyzer.riskFieldsSetting.svgRisks;
                }
            }

            if (!this.handledByAnalyzer) { // this could have changed since load start in the meantime, so double check it!
                let svgResult = await new HttpClient().createRequest('images/' + svgName + '?_t=' + new Date().valueOf()).asGet().send();
                if (svgResult.statusCode < 400) {
                    if (this.svgRiskParent)
                        this.svgRiskParent.innerHTML = this.svgImage =  svgResult.response;
                } else {
                    console.warn('Error loading SVG Image:', svgResult.statusCode + ' - ' + svgResult.statusText)
                }
            }
        }
    }

    resetArrows() {
        if (this.handledByAnalyzer) return;
        for (let i = 0; i < 9; i++) this.setArrowColor(i, "n");
    }

    resetGroupColorsAndValues() {
        if (this.handledByAnalyzer) return;
        this.pages.forEach(page => {
            page.groups.forEach(group => {
                group.currentValue = undefined;
                group.previousValue = undefined;
                group.color = "n";
                group.hasRisk = undefined;
                this.setArrowColor(group.link, "n");
                this.setOuterColor(page.groups.indexOf(group), "n");
            })
        })
    }

    updateArrowsFromRisks(resources: any[]) {
        if (this.handledByAnalyzer) return;
        this.resetGroupColorsAndValues();

        // if (resources.length < 2) return;

        resources = [resources[resources.length - 1], resources[resources.length - 2]];

        if (!this.patient) return;
        let anamnesisResponses = QuestionnaireService.GetResponsesOfType(this.patient, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        let anamneseCurrent = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        let anamnesePrevious = anamnesisResponses.length > 1 ? anamnesisResponses[anamnesisResponses.length - 2] : anamneseCurrent;

        let latestAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        this.pages[0].groups.forEach(grp => {
            grp.hasRisk = false;
            if (grp.riskLinkId) {
                let riskItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(latestAssessment, grp.riskLinkId, false);
                if (riskItem) {
                    let intVal = Fhir.QuestionnaireResponse.GetResponseItemValueInt(riskItem, 0);
                    grp.hasRisk = intVal === 1;
                }
            }
        });

        // assign values to page.group.currentValue and page.group.prevValue from the resources we downloaded in loadRisks()
        if (resources.length >= 2) {
            for (let res = 0; res < resources.length; res++) {
                let resource = resources[res];
                for (let p = 0; p < this.pages.length; p++) {
                    let page = this.pages[p];
                    page.outCome = NaN;

                    for (let g = 0; g < page.groups.length; g++) {
                        let group = page.groups[g];
                        group.color = "n";
                        group.outCome = 0;

                        if (group.valueLinkId) {
                            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(resource, group.valueLinkId, false);
                            if (!item) {
                                item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(res === 0 ? anamnesePrevious : anamneseCurrent, group.valueLinkId);
                            }

                            if (item) {
                                let itemValueInt: number = Fhir.QuestionnaireResponse.GetKeyValueAsNumber(item);

                                if (typeof itemValueInt !== "undefined") {
                                    if (typeof group.currentValue === "undefined" || group.currentValue === -1) {
                                        group.currentValue = itemValueInt;
                                    } else {
                                        if (typeof group.previousValue === "undefined" || group.previousValue === -1) {
                                            group.previousValue = itemValueInt;
                                        } // if group.previousValue
                                    } // if !group.currentValue

                                    group.outCome = 0;
                                    if (typeof group.previousValue !== "undefined" && typeof group.currentValue !== "undefined") {
                                        if (group.previousValue > group.currentValue) {
                                            group.color = "r";
                                            group.outCome = -1;
                                            page.outCome += -1;
                                        } else if (group.previousValue < group.currentValue) {
                                            group.color = "g";
                                            group.outCome = 1;
                                            page.outCome += 1;
                                        } else {
                                            group.color = 'n';
                                            group.outCome = 0;
                                        }
                                    }
                                } // if itemValueInt
                            } // if item
                        } // if group.valueLinkId
                    } // for groups
                } // for pages
            } //for resources
            //#endregion
        }

        for (let i = 0; i < this.pages[0].groups.length; i++) {
            let g = this.pages[0].groups[i];
            let correspondingPage = this.pages[i + 1];

            g.outCome = 0;
            for (let cgI = 0; cgI < correspondingPage.groups.length; cgI++) {
                g.outCome += correspondingPage.groups[cgI].outCome;
            }

            // if (ConfigService.Debug) console.debug(`Mapping Group Values on Page[0], Group ${g.text} to this.pages[${i}] ${correspondingPage.title}`, g, correspondingPage);
            g.color = g.outCome === 0 ? 'n' : g.outCome > 0 ? 'g' : 'r';
        }

        this.updateArrowsOnPage(this.activePageIndex);

        return this;
    }

}

export class RiskOverViewPage {
    private _title?: string = "unnamed";
    get title(): string {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    groups: RiskOverViewGroup[] = [];
    tr?: boolean = false;
    outCome?: number = 0;

    constructor(title: string) {
        this._title = title;
        this.groups = [];
        this.outCome = 0;
    }
}

export class RiskOverViewGroup {
    text: string;
    isVisible: boolean = true;
    link?: number;
    tr: boolean;
    valueLinkId?: string;
    currentValue?: any;
    previousValue?: any;
    color?: string = "n";
    riskLinkId?: string;
    hasRisk?: boolean = false;
    outCome?: number = 0;
}
