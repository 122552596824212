import {bindable, observable, bindingMode} from 'aurelia-framework';

const moment = require("moment");
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {PatientItem} from "../classes/Patient/PatientItem";

export class PatientCurveDatetime {
    @bindable({defaultBindingMode: bindingMode.twoWay}) dateFrom;
    @bindable({defaultBindingMode: bindingMode.twoWay}) dateTo;
    @bindable dateFromChange;
    @bindable dateToChange;
    @bindable patient: PatientItem;

    @observable dateFromElement;
    @observable dateToElement;

    dateFromObject: Date;
    dateToObject: Date;

    _dateFromValue;
    _dateToValue;

    dpOptions = {
        useCurrent: true,
        format: RuntimeInfo.DateFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        keepInvalid: true,
        focusOnShow: false
    };

    minDate;
    maxDate;

    dateFromChanged(val) {
        this.dateFromObject = moment(val).toDate();
    }

    dateToChanged(val) {
        this.dateToObject = moment(val).toDate();
    }

    set dateFromValue(val) {
        this.dateFromChange(val);

        const min = moment(this.dateFromObject).startOf('day');
        let max = moment(this.dateFromObject).add(6, 'days').endOf('day');
        let maxEncounter = this.patient && this.patient.encounter && this.patient.encounter.period && this.patient.encounter.period.end ? moment(this.patient.encounter.period.end).endOf('day') : moment().endOf('day');

        if (max.isAfter(maxEncounter)) {
            max = maxEncounter;
        }

        this.dateToElement.methods.maxDate(max.toDate());
        this.dateToElement.methods.minDate(min.toDate());

        if (this._dateFromValue && this._dateToValue) {
            const diff = moment(this._dateToValue, 'DD.MM.YYYY').diff(moment(this._dateFromValue, 'DD.MM.YYYY'), 'days');
            const dateTo = moment(this.dateFromObject).add(diff, 'days').endOf('day');

            if (dateTo.isAfter(max)) {
                this.dateToObject = max.toDate();
            } else {
                this.dateToObject = dateTo.toDate();
            }
        }

        this.dateFromChange({val: moment(val, 'DD.MM.YYYY')});

        this._dateFromValue = val;
    }

    set dateToValue(val) {
        this.dateToChange({val: moment(val, 'DD.MM.YYYY')});

        this._dateToValue = val;
    }

    patientChanged() {
        this.maxDate = this.patient && this.patient.encounter && this.patient.encounter.period && this.patient.encounter.period.end ? moment(this.patient.encounter.period.end).toDate() : moment().toDate();
        this.minDate = this.patient && this.patient.encounter && this.patient.encounter.period && this.patient.encounter.period.start ? moment(this.patient.encounter.period.start).toDate() : moment(this.maxDate).subtract(7, 'days');

        if (this.dateFromElement) {
            this.dateFromElement.methods.minDate(this.minDate);
            this.dateFromElement.methods.maxDate(this.maxDate);
        }
    }

    prevDay() {
        if (!this.minDate) {
            return;
        }

        if (moment(this.dateFromObject).isAfter(moment(this.minDate))) {
            this.dateFromObject = moment(this.dateFromObject).subtract(1, 'day').toDate();
        }
    }

    nextDay() {
        if (!this.maxDate) {
            return;
        }

        if (moment(this.dateFromObject).isBefore(moment(this.maxDate))) {
            this.dateFromObject = moment(this.dateFromObject).add(1, 'day').toDate();
        }
    }
}
