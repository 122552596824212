import {bindable, inject} from "aurelia-framework";
import {Router} from "aurelia-router";
import {DialogService} from "aurelia-dialog";
import {ModalBodyMap} from "../modal-body-map";
import {Modal3dBody} from "../modal-3dbody";
import {GrafixxItem, IGrafixxItem} from "../../classes/Grafixx-item";
import {I18N} from "aurelia-i18n";
import {FhirService} from "../../services/FhirService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {ConfigService} from "../../services/ConfigService";

const STRUCTURE_DEFINITION = 'http://nursit-institute.com/fhir/StructureDefinition/'

@inject(Router, DialogService, I18N, FhirService)
export class patientGrafixx {
    loading: boolean = false;
    router: Router = undefined;
    dialogService: DialogService;
    fhirService: FhirService;
    i18n: I18N;
    itemImagePrefix: string = "./images/bodies/icons";
    currentEncounterId: string = undefined;
    is3dBody: boolean = false;
    markersCodeSystem: any;
    markers = [];

    @bindable get patient(): PatientItem {
        return PatientItem.SelectedPatient;
    }

    get items(): IGrafixxItem[] {
        return GrafixxItem.Default;
    }

    constructor(
        router: Router,
        service: DialogService,
        i18n: I18N,
        fhirService: FhirService
    ) {
        this.router = router;
        this.dialogService = service;
        this.i18n = i18n;
        this.fhirService = fhirService;

        const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
        this.is3dBody = Boolean(config.settings?.body3d?.enabled);
    }

    openWoundForm() {
        this.dialogService
            .open({
                viewModel: this.is3dBody ? Modal3dBody : ModalBodyMap,
                model: {
                    patient: this.patient,
                },
                centerHorizontalOnly: this.is3dBody,
            })
            .whenClosed((result) => {
                if (!result.wasCancelled || this.is3dBody) {
                    this.loadObservations();
                }
            });
    }

    calculateSums(observations: any[]) {
        try {
            if (this.items) {
                this.resetSums();

                for (const item of this.items) {
                    const itemObservations = observations.filter(
                        (o) =>
                            o.status !== "cancelled" &&
                            o.status !== "entered-in-error" &&
                            o.category &&
                            o.category[0] &&
                            o.category[0].text === item.type
                    );

                    item.isWound = item.type === "wound";
                    item.sum = itemObservations.length;
                    item.healedCount = itemObservations.filter(o => o.effectivePeriod && o.effectivePeriod.end).length;
                }
            }
        }
        catch (e) {
            console.warn(e.message||e);
        }
    }

    async calculate3dMarkers() {
        this.markers = [];

        for (const concept of this.markersCodeSystem.concept) {
            const observations = <any[]>await this.fhirService.fetch(`Observation?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${this.patient.encounterId}&code=${STRUCTURE_DEFINITION}body|marker&component-code=${concept.code}&status=registered`);
            const icon = concept.extension.find((e:any) => e.url.endsWith('codesystem-attachment'))

            this.markers.push({
                name: concept.display,
                amount: observations.length,
                icon: icon?.valueAttachment?.data && `data:${icon.valueAttachment.contentType};base64,${icon.valueAttachment.data}` || ''
            })
        }
    }

    resetSums() {
        if (this.items) {
            for (const item of this.items) {
                item.sum = 0;
            }
        }
    }

    async loadObservations() {
        if (!this.patient || (this.loading && this.patient.encounterId == this.currentEncounterId)) {
            if (!this.patient)
                this.loading = false;

            return;
        }

        this.loading = true;
        try {
            if (this.is3dBody) {
                await this.calculate3dMarkers()
            } else {
                let url = `/Observation?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${this.patient.encounterId}&status=amended,corrected,final,preliminary,registered`;

                const result = <any[]>await this.fhirService.fetch(url);

                this.calculateSums(result);
            }
        }
        catch (e) {
            console.warn(e.message||e)
        }
        finally {
            this.loading = false;
        }
    }

    async patientChanged(newPatient: PatientItem) {
        if (this.is3dBody) {
            this.markersCodeSystem = (<any[]>await this.fhirService.fetch(`CodeSystem?system=${STRUCTURE_DEFINITION}body-markers`))[0]
        }

        if (newPatient && this.currentEncounterId !== newPatient.encounterId) {
            this.currentEncounterId = newPatient.encounterId; // to avoid double loading
            await this.loadObservations();
        }
    }

    async attached() {
        if (ConfigService.Debug) window["grafixx"] = this;

        this.resetSums();

        await GrafixxItem.Init(this.i18n);
        for (const item of this.items) {
            item.text = this.i18n.tr(item.type);
            item.svg = `${this.itemImagePrefix}/${item.imageName}.svg`;

            item.svgHealed = `${this.itemImagePrefix}/${item.imageName}_bordered.svg`;
        }

        if (this.patient) {
            await this.loadObservations();
        }
    }
}
